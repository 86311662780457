<template>
	<div class="wrap">
		<div class="flex justify-between">
			<el-form :inline="true" :model="searchForm">
				<el-form-item label="关键字">
					<el-input v-model="searchForm.search" placeholder="姓名/手机号码" @submit.native.prevent></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" icon="el-icon-search" @click="loadData()">查询</el-button>
				</el-form-item>
			</el-form>
			<!-- 操作按钮 -->
			<el-form :inline="true">
				<el-form-item>
					<el-button @click="$router.push(`/tables/customer/edit`)">新增顾客档案</el-button>
				</el-form-item>
			</el-form>
		</div>

		<el-table :data="tableData" v-loading="loading" max-height="9999">
			<el-table-column type="index" width="50"></el-table-column>
			<el-table-column prop="real_name" label="客户姓名" width="200"></el-table-column>
			<el-table-column prop="created_at" label="年龄" width="200"></el-table-column>
			<el-table-column prop="birthday" label="出生日期" width="100">
				<template slot-scope="scope">
					{{scope.row.birthday && scope.row.birthday.substring(0,10)}}
				</template>
			</el-table-column>
			<el-table-column prop="native_place" label="职业单位" width="100"></el-table-column>
			<el-table-column prop="phone" label="电话" width="200"></el-table-column>
			<el-table-column prop="postal_address" label="通讯地址"></el-table-column>
			<el-table-column fixed="right" label="操作" width="240">
				<template slot-scope="scope">
					<el-button size="mini" @click="$router.push(`/tables/customer/edit/${scope.row.id}`)">编辑</el-button>
					<el-button size="mini" @click="$router.push(`/tables/customer/detail/${scope.row.id}`)">查看详情
					</el-button>
					<el-button size="mini" type="danger" icon="el-icon-delete" @click="del(scope.row)"></el-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="text-center margin-top">
			<el-pagination @current-change="pageChange" :current-page="searchForm.page" :page-sizes="vuex_page_sizes"
				:page-size="searchForm.page_size" layout="total, sizes, prev, pager, next, jumper" :total="total"
				@size-change="pageSizeChange">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				loading: false,
				tableData: [],
				searchForm: {
					search: '',
					page: 1,
					page_size: 10
				},
				total: 0
			};
		},
		mounted() {
			this.loadData();
		},
		methods: {
			loadData() {
				this.loading = true;
				this.$api.table.getCustomerList(this.searchForm).then().then(res => {
					this.loading = false;
					this.tableData = res.data.data;
					this.total = res.data.total;
				});
			},
			pageSizeChange(size) {
				this.searchForm.page_size = size;
			},
			pageChange(page) {
				this.searchForm.page = page;
				this.loadData()
			},
			del(row) {
				this.$confirm('您确定要删除吗？', '提示', {
					type: 'warning'
				}).then(() => {
					this.$api.table.delCustomerById({id: row.id}).then(res=>{
						console.log(res)
						if(res.code == 200){
							this.$message.success('操作成功')
							this.loadData()
						}
					})
				}).catch(_ => {console.log(_)})
			}
		}
	};
</script>
<style scoped lang="scss">
	.cover {
		width: 40px;
		height: 40px;
		border-radius: 5px;
		margin-right: 5px;
	}
</style>
